import { useTranslation } from 'react-i18next';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { MdExpandMore } from 'react-icons/md';

import useStyles from './AppLegalInfo.styles';

const AppLegalInfo = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const items = [...Array(7).keys()].map(index => ({
    description: t(`app.legalInformation.items.${index + 1}.description`),
    title: t(`app.legalInformation.items.${index + 1}.title`),
  }));

  return (
    <Box className={classes.root}>
      <Accordion>
        <AccordionSummary
          aria-controls="legal-info-content"
          className={classes.heading}
          expandIcon={<MdExpandMore />}
          id="legal-info-content"
        >
          <Typography className={classes.headingText}>
            {t('app.legalInformation.title')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.content}>
          {items.map(({ description, title }) => (
            <Box key={title} className={classes.item}>
              <Typography variant="button">
                {title}
              </Typography>
              <Typography cvariant="body2">
                {description}
              </Typography>
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default AppLegalInfo;
