import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
  },
  heading: {
    borderBottom: '1px solid #ccc',
  },
  headingText: {
    color: theme.palette.primary.dark,
    fontSize: theme.typography.pxToRem(16),
  },
  item: {
    '& p': {
      color: theme.palette.primary.main,
      fontSize: '0.92rem',
    },
    '& span': {
      color: theme.palette.primary.dark,
      fontWeight: 600,
    },
    marginBottom: theme.spacing(1.5),
  },
  root: {
    width: '100%',
  },
}));
